<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        style="height: 500px; overflow-y: hidden"
        v-model="html"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onChange="onChange"
        @onCreated="onCreated"
    />
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import {mapState} from "vuex"
export default Vue.extend({
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "",
      currentValue:'',
      // 工具栏配置（注意结构，否则不起作用）
      toolbarConfig: this.options,
      // 菜单配置（注意结构，否则不起作用）
      editorConfig: {
        excludeKeys: ["uploadVideo"],
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // 自定义上传图片 方法
            customUpload: this.uploadImg,
            // 上传接口设置文件名
            fieldName: "file",
            meta: {},
          },
          uploadVideo: {
            customUpload: this.uploadVideo,
            fieldName: "file",
            meta: {},
          }
        },
      },
      mode: "simple", // or 'simple' / 'default'
      // 图片、视频上传服务器地址
      uploadFileUrl: process.env.VUE_APP_BASE_URL + '/file/upload',
    };
  },
  computed: {
    ...mapState(['ip'])
  },
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: "",
    },
    /* 配置 */
    options: {
      type: Object,
      default: () => ({
        excludeKeys :[
          'todo',
          'codeBlock'
        ]
      })
    },
    /* 高度 */
    height: {
      type: Number,
      default: null,
    },
    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null,
    },
    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false,
    },
    // 上传文件大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    /* 类型（base64格式、url格式） */
    type: {
      type: String,
      default: "url",
    },
  },
  watch: {
    // 监听父组件中editor值的变化
    value: {
      handler(val) {
        this.html=val
        this.currentValue=val
      },
      immediate: true,
    },
  },
  methods: {
    onCreated(editor) {
      // 一定要用 Object.seal() ，否则会报错
      this.editor = Object.seal(editor);
    },
    onChange(editor){
      // this.$emit("change", this.html);
      // this.value=this.html
      this.$emit('input', this.html)
    },
    //自定义上传视频
    uploadVideo(file, insertFn) {
      return this.$message.warn('暂不支持')
      // let imgData = new FormData();
      // imgData.append("file", file);
      // axios({
      //   url: this.uploadFileUrl,
      //   data: imgData,
      //   method: "post",
      // }).then((response) => {
      //   this.$loading.hide()
      //   if (response.data.status === '200') {
      //     // 插入后端返回的url，将图片显示在页面上
      //     insertFn(response.data.data);
      //     this.$message.info('上传成功');
      //   } else {
      //     this.$loading.hide()
      //   }
      // });
    },
    //自定义上传图片
    uploadImg(file, insertFn) {
      let imgData = new FormData();
      this.$loading.show()
      imgData.append("file", file);
      axios({
        url: this.uploadFileUrl,
        data: imgData,
        method: "post",
      }).then((response) => {
        console.log(response);
        this.$loading.hide()
        if (response.data.status === '200') {
          // 插入后端返回的url
          insertFn(response.data.data);
          this.$message.info('上传成功');
        } else {
          this.$loading.hide()
        }
      });
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      if(this.currentValue){
        this.html = this.currentValue
      }
      // 查看菜单配置项
      console.log(this.editor.getConfig());
      // const toolbar = DomEditor.getToolbar(this.editor)
      // const curToolbarConfig = toolbar.getConfig()
      // console.log( curToolbarConfig.toolbarKeys ) // 当前菜单排序和分组
      console.log(this.editor.getMenuConfig(), '查看当前配置')
    }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>